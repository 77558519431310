import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Row } from "react-bootstrap"

const NotFoundPage = props => (
  <Layout location={props.location}>
    <SEO title="404: Page non trouvée" description="Cette page n'existe pas" />
    <Row className="justify-content-center">
      <Col lg={8} md={10} className="mb-4">
        <h1>Page non trouvée</h1>
        <p>
          Mince ! Cette page n’existe pas... Retourner à{" "}
          <a href="/">l'accueil</a>
        </p>
      </Col>
    </Row>
  </Layout>
)

export default NotFoundPage
